import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Main, Works, Board, About, Error404 } from "pages";
import { KthemeMain, KthemeHelp, KthemeRelease } from './pages/Ktheme';

import Header from "./core/Header";
import Transition from "./core/Transition";

import firefly from "js/util/firefly";
import $ from 'jquery';

import "css/reset.css";
import "css/style.css";

const AppRouter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isHeaderNeed = !['/404', '/ktheme'].includes(location.pathname);
    
    const [transitionDirection, setTransitionDirection] = useState(null);
    const [transitionActive, setTransitionActive] = useState(false);

    // 파티클 이펙트
    useEffect(() => {
        firefly();

        return () => {
            if ($.firefly && $.firefly.pause) {
                $.firefly.pause();
            }
        };
    }, []);

    // 우클릭 차단
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
        }
    }, []);
    
    // 메뉴 클릭시 트랜지션
    const handleMenuClick = (direction, path, isExternal = false) => {
        setTransitionDirection(direction);
        setTransitionActive(true);

        setTimeout(() => {
            if (isExternal) {
                window.location.href = path; // 외부 경로로 이동
            } else {
                navigate(path); // 내부 경로로 이동
            }
        }, 1250); // 트랜지션 시간에 맞게 설정
    };

    return (
        <>
            {/* 트랜지션 컴포넌트 */}
            {transitionActive && (
              <Transition
                direction={transitionDirection}
                onFinishLoading={() => {
                  setTimeout(() => setTransitionActive(false), 500); // 닦아내는 애니메이션 이후 제거
                }}
              />
            )}
  
            {/* 루트 페이지 */}
            {isHeaderNeed && <Header onMenuClick={handleMenuClick} />}
            <Routes>
                {/* 메인 페이지 */}
                <Route index element={<Main />} />

                {/* 소개 페이지 */}
                <Route path="/about" element={<About />} />

                {/* 방명록 페이지 */}
                <Route path="/board" element={<Board />} />

                {/* 작업물 리스트 페이지 */}
                <Route path="/works" element={<Works />} />

                {/* 404 에러 페이지 */}
                <Route path="/404" element={<Error404 />} />
                <Route path="*" element={<Navigate to="/404" replace />} />

                {/* Project RGB 페이지 */}
                {/* <Route path="/ktheme" element={<KthemeMain />} />
                <Route path="/ktheme/help" element={<KthemeHelp />} />
                <Route path="/ktheme/download/:themeId" element={<KthemeRelease />} /> */}
            
            </Routes>
        </>
    );
}

export default AppRouter;