import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import icons from "img/icons/header.svg";
// import click from "sound/click.wav"

const Header = ({ onMenuClick }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    }
    const location = useLocation();

    // const playClickSound = () => {
    //     const audio = new Audio(click);
    //     audio.play().catch((error) => {
    //         console.error("오디오 재생 중 오류 발생:", error);
    //     });
    // };

    // 현재 경로에 따라 `active` 클래스 설정
    const isActive = (path) => location.pathname === path;

    const handleClick = (direction, path, isExternal = false) => {
        setMenuOpen(false); // 메뉴를 닫음
        onMenuClick(direction, path, isExternal);
    };

    return (
        <>
            <div className={`header-container ${menuOpen ? "open" : ""}`}>

                {/* 버튼 */}
                <div className="header-trigger" onClick={toggleMenu} aria-label="Toggle navigation">
                    <div className="hamburger">
                        <span className={`line ${menuOpen ? "rotate-down" : ""}`}></span>
                        <span className={`line ${menuOpen ? "fade-out" : ""}`}></span>
                        <span className={`line ${menuOpen ? "rotate-up" : ""}`}></span>
                    </div>
                </div>

                {/* 펼쳐지는 메뉴 */}
                {menuOpen && (
                    <ul className="header-items">
                        <li className={`header-item ${isActive("/") ? "active" : ""}`}>
                            <Link to="#" onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("top", "/")
                                                }}>
                                <svg className="icon">
                                    <use href={`${icons}#icon-home`} />
                                </svg>
                            </Link>
                        </li>
                        <li className={`header-item ${isActive("/about") ? "active" : ""}`}>
                            <Link to="#" onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("bottom", "/about")
                                                }}>
                                <svg className="icon">
                                    <use href={`${icons}#icon-profile`} />
                                </svg>
                            </Link>
                        </li>
                        <li className={`header-item ${isActive("/works") ? "active" : ""}`}>
                            <Link to="#" onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("left", "/works")
                                                }}>
                                <svg className="icon">
                                    <use href={`${icons}#icon-folder`} />
                                </svg>
                            </Link>
                        </li>
                        <li className={`header-item`}>
                            <Link to="#" onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("right", "https://294.ink/blog", true);
                                                }}>
                                <svg className="icon">
                                    <use href={`${icons}#icon-blog`} />
                                </svg>
                            </Link>
                        </li>
                        {/* <li className={`header-item ${isActive("/board") ? "active" : ""}`}>
                            <Link to="/board" onClick={onMenuClick}>
                                <svg className="icon">
                                    <use href={`${icons}#icon-chat`} />
                                </svg>
                            </Link>
                        </li> */}
                    </ul>
                )}
            </div>
        </>
    );
};

export default Header;
